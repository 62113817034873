// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        topbarHome: "Home",
        backgroundImageTitle2023: `1<sup style="text-transform: none">st</sup> INTERNATIONAL CONFERENCE`,
        backgroundImageTitle: `2nd INTERNATIONAL CONFERENCE`,
        backgroundImageTitle2:
          "TRENDS AND CHALLENGES OF THE RESORTS SUSTAINABLE DEVELOPMENT",
        backgroundImageDate2023:
          "7<sup>th</sup> December 2023<br /> Tbilisi Georgia",
        backgroundImageDate: "December 5, 2024<br /> Tbilisi Georgia",
        backgroundImageRegister: "Register Now",
        navbarConferance: "Conference",
        navbarProgram: "Programme",
        navbarOrg: "Organiser ",
        navbarReg: "Registration",
        navbarContact: "Contact",
        conferanceMission: "Mission",
        conferance1: `1<sup>st</sup> International Conference “Trends and Challenges of the Resorts Sustainable Development” aims to identify emerging trends and challenges in sustainable resort development, exploring opportunities to maximize economic potential and social benefits through the development of climate and balneological resorts. 
        The conference will discuss the need to develop the resort industry and the most effective international resort management models. It is an excellent opportunity to devise a new strategic approach to resort industry development 
        `,
        conferance2: `The conference will address the need to develop attractive destinations to stimulate activities in these areas and encourage businesses to invest in field development.
        The conference will offer an overview of resort destinations within a broader context and will cover topics related to three major pillars: 
        `,
        conferance3: `<ul>
        <li>
        Resorts Sustainable Development: Trends, Challenges, and Future Prospects
        </li>
        <li>Wellness and Balneology in Healthcare and Well-being</li>
        <li>Economic and Social Impacts of Resort Development</li>
      </ul>
      <br />
      This conference will bring together experts from both the local and international resort industry <br />
      <br />
      <b>Participants:</b> <br /> Approximately 400 participants, including government officials, local authorities, DMOs, private sector representatives, international organizations and academia
      <br />
      <br /> <b>Official Languages:</b> Georgian and English`,
        footerText: `All Rights Reserved. Designed by MidNight™.`,
        georgiaInfo1: `Georgia is situated at the crossroads of Europe and Asia, to the east of the Black sea. <br /> <br />
        The country's strategic geopolitical location has the potential to attract visitors from all over the world. It is a cluster of diverse cultures, religions, stunning landscapes, and ancient history  <br />
        <br />`,
        georgiaInfo2: `Georgia has a total of 271 resorts and resort areas. Georgia’s rich resort landscape incorporates thermal, sea, mountain, ski, climate and lake resorts which are situated in picturesque locations surrounded by UNESCO heritage sites and historical and cultural monuments `,
        georgiaTitle: "About Georgia",
        speakers2: "Speakers",
        raedMore: "Read more",
        orgParag: `The LEPL Resorts Development Agency was established in 2022 on the initiative of the Prime Minister of Georgia and operates under the Ministry of Economy and Sustainable Development of Georgia. 
        Considering the huge potential of Georgia’s natural and cultural resources and diversified resorts, including, wellness, mountain, thermal, lake and sea resorts. The Government of Georgia has designated resorts development as one of the Country’s key priority to foster economic development.
        `,
        orgParag1: `
        Mission of the Resorts Development Agency:
        <br /> <br />
        <ul>
          <li>
          Develop policies and development concept and strategy of the resorts based on the state policy and feasibility studies;
          </li>
          <li>
          	Enhance overall resort industry and ultimately, drive economic growth, and regional development;
          </li>
          <li>
          Identify and promote resorts and potential resort areas;
          </li>
          <li>Promote infrastructure development in resort areas;</li>
          <li>
          Establish close cooperation with private, civil, and public organizations, municipal self-governing bodies, and international organizations;
          </li>
          <li>
          Implement EU standards, regulations, management models and best practices;
          </li>
          <li>
          Develop educational programs and professional standards.
          </li>
        </ul>`,
        successMess: "You have successfully registered",
        successMessQR: "Present received QR code at the event",
        mapTitle: "Venue",
        mapAddress: "20 Telavi Str Tbilisi 0103 Georgia",
        mapAddress2024: `3 Lado Gudiashvili St,   
Tbilisi 1105, Georgia`,
        contactAddress: "7 A.Politkovskaya Str Tbilisi 0186 Georgia",
        name: "Name",
        surname: "Surname",
        country: "Country",
        email: "E-mail",
        organization: "Organisation",
        position: "Position",
        mobileNumber: "Telephone numbers",
        invalidEmail: "Email is not valid!",
        seeLess: "Read less",
        mapAddressParag: "Sheraton Grand Tbilisi Metekhi Palace",
        mapAddressParag2024: "Wyndham Grand Tbilisi",
        visitGeorgia: "Visit Georgia",
        welcomeGuys: "Welcome Speech",
        companyNameG: "LEPL Resorts Development Agency",
        processing: "Request is processing...",
        welcome: "Welcome",
        thankYou: "Thank you for coming!",
        guesNotFOund: "404 Error. Guest Not Found!",
        backToAdmin: "Go back to admin panel",
        successCheckIn: "You have successfully checked in",
        checkInAgain: "You have already checked in",

        "backgroundImageTitle2-2024": "Future of Resorts Development",
      },
    },

    ///////////////////////////////////////// ka
    ka: {
      translation: {
        topbarHome: "მთავარი",
        backgroundImageTitle2023: "პირველი საერთაშორისო კონფერენცია",
        backgroundImageTitle: "მეორე საერთაშორისო კონფერენცია",
        backgroundImageTitle2:
          "კურორტების მდგრადი განვითარების ტენდენციები და გამოწვევები",
        backgroundImageDate2023: "7 დეკემბერი 2023 <br /> თბილისი საქართველო",
        backgroundImageDate: "5 დეკემბერი 2024 <br /> თბილისი საქართველო",
        backgroundImageRegister: "რეგისტრაცია",
        navbarConferance: "კონფერენცია",
        navbarProgram: "პროგრამა",
        navbarOrg: "ორგანიზატორი",
        navbarReg: "რეგისტრაცია",
        navbarContact: "კონტაქტი",
        conferanceMission: "მისია",
        conferance1: `პირველი საერთაშორისო კონფერენცია „კურორტების მდგრადი განვითარების ტენდენციები და გამოწვევები“ მიზნად ისახავს კურორტების მდგრადი განვითარების ტენდენციებისა და გამოწვევების იდენტიფიცირებას და განიხილავს კლიმატური და ბალნეოლოგიური კურორტების განვითარების გზით, ეკონომიკური პოტენციალისა და სოციალური სარგებლის მაქსიმალურად გაზრდის შესაძლებლობას. 
        კონფერენციაზე განიხილება კურორტების ინდუსტრიის განვითარების საჭიროება და მართვის ეფექტური საერთაშორისო მოდელები. ღონისძიება წარმოადგენს საკურორტო ინდუსტრიის განვითარების სტრატეგიული მიდგომების განხილვის შესაძლებლობის პლატფორმას
        `,
        conferance2: `
        კონფერენცია ფართოდ მიმოიხილავს კურორტების განვითარებას და მოიცავს სამ ძირითად მიმართულებას:        
        `,
        conferance3: `<ul>
        <li>
        კურორტების მდგრადი განვითარება: ტენდენციები, გამოწვევები და პერსპექტივები
        </li>
        <li>კეთილდღეობა და ბალნეოლოგია ჯანდაცვისა და კეთილდღეობის სფეროში </li>
        <li>კურორტების განვითარების ეკონომიკური და სოციალური გავლენა</li>
      </ul>
      <br />
      კონფერენციაში მონაწილეობას მიიღებენ ადგილობრივი და საერთაშორისო საკურორტო ინდუსტრიის ექსპერტები <br />
      <br />
      <b>მონაწილეები: </b> <br /> 400-მდე მონაწილე, მათ შორის სახელმწიფო და კერძო სექტორის, ადგილობრივი თვითმმართველობის, DMO-ების, საერთაშორისო ორგანიზაციებისა და აკადემიური წრეების 400-მდე წარმომადგენელი
      <br />
      <br /> <b>ოფიციალური ენები: </b>ქართული და ინგლისური`,
        footerText: `ყველა უფლება დაცულია. შექმნილია MidNight™-ის მიერ`,
        georgiaInfo1: `საქართველო ევროპისა და აზიის გზაგასაყარზე, შავი ზღვის აღმოსავლეთით მდებარეობს.  <br /> <br />
        ქვეყნის სტრატეგიული გეოპოლიტიკური მდებარეობა განაპირობებს ვიზიტორების მოზიდვას მთელი მსოფლიოდან. ეს არის მრავალფეროვანი კულტურის, რელიგიის, ლანდშაფტისა და უძველესი ისტორიის ქვეყანა<br />
        <br />`,
        georgiaInfo2: `საქართველოში 271 კურორტი და საკურორტო ადგილია. საქართველოს მდიდარი საკურორტო ლანდშაფტი მოიცავს თერმულ, ზღვის, მთის, ტბის, სათხილამურო და კლიმატურ კურორტებს, რომლებიც განლაგებულია თვალწარმტაც ადგილებში და გარშემორტყმულია იუნესკოს მემკვიდრეობისა და ისტორიულ-კულტურული ძეგლებით`,
        georgiaTitle: "საქართველოს შესახებ",
        speakers2: "მომხსენებლები",
        raedMore: "წაიკითხეთ მეტი",
        orgParag: `კურორტების განვითარების სააგენტო საქართველოს პრემიერ-მინისტრის ინიციატივით, 2022 წელს შეიქმნა და არის საქართველოს ეკონომიკისა და მდგრადი განვითარების სამინისტროს საჯარო სამართლის იურიდიული პირი. <br /> <br />
        საქართველოს ბუნებრივი და კულტურული რესურსებისა და დივერსიფიცირებული კურორტების, მათ შორის, გამაჯანსაღებელი, მთის, თერმული, ტბის და ზღვის კურორტების პოტენციალის გათვალისწინებით, საქართველოს მთავრობამ კურორტების განვითარება, ქვეყნის ეკონომიკური განვითარების ერთ-ერთ მთავარ პრიორიტეტად განსაზღვრა.
        `,
        orgParag1: `
        კურორტების განვითარების სააგენტოს მისია:
        <br /> <br />
        <ul>
          <li>
          სახელმწიფო პოლიტიკისა და ტექნიკურ-ეკონომიკური კვლევის საფუძველზე, კურორტების პოლიტიკის, განვითარების კონცეფციისა და სტრატეგიის შემუშავება;
          </li>
          <li>
          საკურორტო ინდუსტრიის გაძლიერება და ეკონომიკური ზრდისა და რეგიონული განვითარების სტიმულირება;
          </li>
          <li>
          კურორტებისა და პოტენციური საკურორტო ზონების იდენტიფიცირება და პოპულარიზაცია;
          </li>
          <li>საკურორტო ზონებში ინფრასტრუქტურის განვითარების ხელშეწყობა;</li>
          <li>
          კერძო, სამოქალაქო, საჯარო და საერთაშორისო ორგანიზაციებთან, ასევე, ადგილობრივი თვითმმართველობის ორგანოებთან მჭიდრო თანამშრომლობა;
          </li>
          <li>
          ევროკავშირის სტანდარტების, რეგულაციების, მართვის მოდელებისა და საუკეთესო პრაქტიკის დანერგვა;
          </li>
          <li>
          საგანმანათლებლო პროგრამებისა და პროფესიული სტანდარტების შემუშავება.
          </li>
        </ul>`,
        successMess: "თქვენ წარმატებით დარეგისტრირდით",
        successMessQR:
          "გთხოვთ, ღონისძიებაზე წარმოადგინოთ ელ.ფოსტაზე მიღებული QR კოდი",
        mapTitle: "კონფერენციის ადგილი",
        mapAddress: "საქართველო თბილისი 0103 თელავის ქ.20",
        contactAddress: "ა.პოლიტკოვსკაიას 7 საქართველო 0186 თბილისი",
        name: "სახელი",
        surname: "გვარი",
        country: "ქვეყანა",
        email: "ელ-ფოსტა",
        organization: "ორგანიზაცია",
        position: "პოზიცია",
        mobileNumber: "ტელეფონის ნომერი",
        invalidEmail: "ელ-ფოსტა არავალიდურია!",
        seeLess: "წაიკითხეთ ნაკლები",
        mapAddressParag: "შერატონ გრანდ მეტეხი პალასი",
        visitGeorgia: "ეწვიე საქართველოს",
        welcomeGuys: "მისასალმებელი მიმართვა",
        companyNameG: "სსიპ კურორტების განვითარების სააგენტო",
        processing: "მოთხოვნა მუშავდება...",
        welcome: "მოგესალმებით",
        thankYou: "Მადლობა მობრძანებისათვის!",
        guesNotFOund: "404 Error. სტუმარი ვერ მოიძებნა!",
        backToAdmin: "დაუბრუნდით ადმინისტრაციულ პანელს",
        successCheckIn: "თქვენ წარმატებით დარეგისტრირდით",
        checkInAgain: "თქვენ უკვე წარმატებით დარეგისტრირდით",
      },
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
