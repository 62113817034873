import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import LogoGeoWhiteGeo from "../assets/images/Logo-GEO_White.png";
import LogoGeoWhiteEng from "../assets/images/Logo-ENG_White.png";

import LogoSamWhiteEng from "../assets/images/logo ministr-06.png";
import LogoSamWhiteGeo from "../assets/images/logo ministr-07.png";

import { useEffect, useState } from "react";
import i18n from "../i18n";

import Pin from "../assets/images/pin.png";
import Email from "../assets/images/email.png";
import Call from "../assets/images/call.png";

function Footer() {
  const { t } = useTranslation();

  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("lang") || "en"
  );

  useEffect(() => {
    i18n.changeLanguage(currentLang);
    // setCurrentLang(localStorage.getItem("lang"));
  }, [currentLang]);

  return (
    <footer
      className="footer text-light text-center py-4 mt-5"
      style={{
        backgroundColor: "transparent",
      }}
    >
      <div className="row mx-auto mt-4" style={{ maxWidth: "90%" }}>
        <div id="contact">
          <div>
            {/* <img
            className="background-image"
            src={BackgrounHalf}
            alt="Background Image"
          /> */}
          </div>
        </div>
        <div className="col-lg-6 text-lg-start">
          <div className="">
            <h3
              className="ps-4 pe-4 d-none"
              style={{ color: "rgb(252, 212, 41)" }}
            >
              {t("navbarContact")}
            </h3>
            <div className="" style={{ color: "white" }}>
              <h2 className="mb-3" style={{ color: "rgb(252, 212, 41)" }}>
                {t("companyNameG")}
              </h2>
              <div className="p-2">
                <p>
                  <img
                    src={Pin}
                    alt=""
                    className="me-1"
                    width={"16px"}
                    height={"16px"}
                  />{" "}
                  {t("contactAddress")}
                </p>
                <p>
                  <img
                    src={Call}
                    className="me-2"
                    alt=""
                    width={"16px"}
                    height={"16px"}
                  />
                  +995 32 2 50 03 38
                </p>
                <p>
                  <img
                    src={Email}
                    className="me-2"
                    alt=""
                    width={"16px"}
                    height={"16px"}
                  />
                  conference@resorts.gov.ge
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-4 text-lg-end">
          <img
            src={currentLang !== "en" ? LogoSamWhiteGeo : LogoSamWhiteEng}
            className="footer-images"
            alt=""
            style={{
              marginTop: currentLang !== "en" ? "-12px" : "-6px",
              width: "150px",
            }}
          />
          <img
            src={currentLang !== "en" ? LogoGeoWhiteGeo : LogoGeoWhiteEng}
            alt=""
            className="footer-images"
            style={{
              marginTop: currentLang !== "en" ? "-12px" : "-6px",
              width: "200px",
            }}
          />
        </div>
        <div
          class="col-12 footer-text-copyright text-center pt-4"
          style={{ borderTop: "1px solid gray" }}
        >
          <div>&#169; {t("footerText")}</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
