import Speaker1 from "../assets/images/speaker1.jpg";
import Speaker2 from "../assets/images/Speakers-02.png";
import Speaker3 from "../assets/images/Speakers-03.png";
import Speaker5 from "../assets/images/Speakers-05.png";
import Speaker6 from "../assets/images/Speakers-04.png";

import hiruka from "../assets/images/სპიკერები-03.png";
import xudura from "../assets/images/სპიკერები-01.png";
import markf from "../assets/images/სპიკერები-02.png";

import ger from "../assets/images/ger.png";
import tam from "../assets/images/Photo - Tamar Gabunia-02.png";

import sergio from "../assets/images/სერგიო-28.png";

import GrayUser from "../assets/images/rrrrrrrr.png";

import Tevzadze from "../assets/images/tevzadze.png";
import tbc from "../assets/images/tbc.png";
import Student from "../assets/images/stud.png";

import SR from "../assets/images/rrrrrAAAA.png";

import ninoTand from "../assets/speakers2024/ninoTand.png";
import mariaPie from "../assets/speakers2024/mariaPie.png";
import papasKaci from "../assets/speakers2024/papsi.png";
import csillia from "../assets/speakers2024/csilla.png";
import drCurt from "../assets/speakers2024/curt.png";
import coplin from "../assets/speakers2024/coplin.png";

export const speakersArrEng2024 = [
  {
    id: 0,
    name: "Nino Tandilashvili",
    position: `Ms. Nino Tandilashvili, the first Deputy
Minister of the Ministry of Environmental
Protection and Agriculture of Georgia`,
    description: `Ms. Nino Tandilashvili is the first Deputy Minister of the Ministry of Environmental
Protection and Agriculture of Georgia from April 2023 (She was Deputy Minister of
Environmental Protection and Agriculture of Georgia between 12.2017- 04.2023).
She is in charge of key national environmental policy directions including climate
change, ambient air protection, water resources management, environmental
permits and licensing, as well as development of legal framework, international
relations and European Integration, facilitation of environmental education,
supporting public participation in decision-making and ensuring access to
information. Moreover, monitoring of environmental pollution, monitoring of
meteorological, hydrological, geological processes, as well as collection and
dissemination of environmental monitoring data at national and international levels
falls under the custodianship.
Prior to her current position, Ms. Tandilashvili was Deputy Head of the Legal
Department of the Ministry of Environment and Natural Resources Protection of
Georgia and was responsible for elaboration of environmental legislation and
coordination of legal aspects of environmental dimensions for the EU integration.
Starting from 2007 she has developed an interesting and extensive career and has
gained significant experience in the fields of lawmaking and environmental
protection. She has graduated her bachelor in law from Tbilisi State University,
Master in European and Internation Law from University of Amsterdam. She has
started her career as a public servant at the Ministry of Penitentiary, Probation and
Legal Assistance of Georgia, from 2014 she was holding different positions at the
Ministry of Environment, as the Head of the Division of Parliamentary Affairs and
Lawmaking, Deputy head of the Legal Department of the Ministry of Environment
and Natural Resources Protection of Georgia (2014-2017).
In addition to her successful career Ms. Tandilashvili has made a significant
contribution in development of strategic environmental documents such as “The
Environmental Assessment Code of Georgia”, “The Law on Radioactive Waste
Management of Georgia”,
“The Law on Water Resources Management of Georgia”, “The Law on Industrial
Emmissions of Georgia”, “The Law on Environmental Liability of Georgia”, “Ambient
Air Quality Management Legislation”. She is also an Assistant Professor at the
Georgian Institute of Public Affairs and reading lectures for master students.`,
    img: ninoTand,
  },
  {
    id: 1,
    name: "Dr. MARIA PIA ANCORA",
    position: `Senior Urban Development Specialist, ADB Sectors Group`,
    description: `For the past 20 years, Maria Pia has designed and implemented sustainable investment
programs and offered environmental policy advisory across Asia (China, India,
Mongolia, Pakistan, and others). Based in the dynamic South Caucasus since 2019,
she oversees ADB’s urban, water, and climate programs in the region.
Hailing from Italy, she holds a Master’s degree in Environmental Engineering and Land
Use Planning from the University of Trento and a Ph.D. in Environmental Science and
Engineering from Tsinghua University in Beijing, P.R. China.`,
    img: mariaPie,
  },
  {
    id: 2,
    name: "Dr Ioannis PAPPAS",
    position: `Director Mediterranean Region, Global Sustainable Tourism Council
CEO &amp; Founder, Green Evolution AE`,
    description: `Dr Ioannis PAPPAS, Founder &amp; CEO of GREEN EVOLUTION SA. is Director for Mediterranean Region
of the Global Sustainable Tourism Council (GSTC). He is a Mechanical Engineer, with PhD in Applied
Mechanics, with over 30 years’ experience in several fields of science, focusing in Sustainability for
Tourism, Energy and Environmental Efficiency in Infrastructure and Buildings, Advising in
standardization of companies and technological implementation of climate adaptation and
mitigation methodologies. He is Lead GSTC Assessor for Destinations, Auditor / Assessor for
Sustainable Tourism Certification Standards, focusing on Hotels, Tour Operators and Destinations,
Lead Trainer and Assessor for GSTC in South East Europe Region. He was an invited speaker in a
large number of seminars and webinars, including European Commission, GSTC and Google.`,
    img: papasKaci,
  },
  {
    id: 3,
    name: "Csilla Mezösi",
    position: "Secretary-General, European Spas Association (ESPA)",
    description: `Csilla Mezősi has been involved in the activities of the European Spas (ESPA) since 2000,
and she has held the position of Secretary-General since 2018. Throughout her career, she
has been engaged in strategic project management for urban and national government
administrations, focusing on the development of projects in the Health Tourism sector.
With over 25 years of experience in the international tourism industry, she has primarily
concentrated on tourism abroad and networks. Ms. Mezősi&#39;s extensive experience
encompasses various areas such as B2B and B2C sales activities, international PR, event
organization, management consultancy, Spa development, cross-marketing for tourism and
healthcare companies, medical tourism promotion, and representing medical spas for health
insurance companies. In her role as Secretary-General, she is actively involved in the EU
Tourism Manifesto Group and has initiated numerous activities and collaborations in the
overseas market with the specialization of well-being, health tourism, and innovations in the
spa business.`,
    img: csillia,
  },
  {
    id: 4,
    name: "Dr. Kurt von Storch",
    position: `CEO, EuropeSpa med &amp; wellness GmbH, Wiesbaden
General Secretary, Quality in Health Prevention
Vice-President, the Academy of Balneology and Climatology of Germany`,
    description: `Dr. Kurt von Storch is a leading expert in the mineral water and spa. He is the CEO of
EuropeSpa med &amp; wellness GmbH, Wiesbaden; General Secretary of Quality in Health
Prevention e.V., Wiesbaden; and Vice-President of the Academy of Balneology and Climatology
(VBK e.V.), the German medical spa expert association. Additionally, he is a scientific member
of the German Spas Association. Dr. von Storch also served as General Secretary of the
Commission on Mineral and Thermal Waters (CMTW) of the IAH International Association of
Hydrogeologists until 2009.
Since 2006, he has been the CEO of EuropeSpa med &amp; wellness GmbH and STORCH Water
Consulting International, Germany. His achievements include: Creation of quality standards and
certification systems for medical and wellness facilities; Conducting training courses on topics
such as thermal water, mud, and climate as therapeutic agents in balneology, as well as quality
aspects in wellness facilities and medical spas; Consulting on the development of local natural
resources, such as thermal water springs, into touristic health destinations.
The previous positions of Dr. Kurt von Storch included:
1999–2005: Institute Fresenius, Germany: Responsible for quality assurance (microbiological
and chemical aspects) in health clinics, health hotels, wellness hotels, spas, and bottling
companies.
1994–1998: UFZ Environmental Research Centre, Germany: Conducted research on mineral and
thermal waters, focusing on quality protection for the spa business; completed a doctoral thesis
on this subject.
Awards:
 Financial Times Germany (2009) for the EuropeSpa med concept
 Two entrepreneur awards from the German state of Hesse and the city of Wiesbaden`,
    img: drCurt,
  },
  {
    id: 5,
    name: "Dr. Marcus Coplin",
    position: `President, the Balneology Association of North America (BANA)`,
    description: `Dr. Marcus Coplin is a president of the Balneology Association of North America
(BANA). He is a Naturopathic Medical Doctor specializing in the field of integrative
Physical &amp; Rehabilitation Medicine and integrative Oncology. He has over 15 years
experience in the treatment of complex and chronic illnesses using hydrothermal
therapy, nutrition, physical medicine, and natural medicines in conjunction with
conventional treatment.
He is the leading specialist in Hydrothermal Medicine in North America and is the
Medical Director for The Springs Resort in Pagosa Springs, CO and Murrieta Hot
Springs Resort in Murrieta, CA. He advises on the science-informed use of therapeutic
thermal mineral hot spring water and Health Resort programming.
Dr. Coplin writes and teaches extensively on the re-emergence of Health Resort culture
and programming in the 21st century healthcare and tourism fields. He is the editor-in-
chief for the International Journal of Balneology and Health Resort Medicine and a book
entitled, Health Resort Medicine. He currently consults other practice owners on
structuring their business to deliver high quality patient centered care integrating
treatment centers, technology, patient care systems, and clinical mechanics to deliver a
high value and profitable patient experience. DrCoplin.Com`,
    img: coplin,
  },
];
export const speakersArrGeo2024 = [
  {
    id: 0,
    name: "ნინო თანდილაშვილი",
    position: `ქალბატონი ნინო თანდილაშვილი,
საქართველოს გარემოს დაცვისა და
სოფლის მეურნეობის მინისტრის
პირველი მოადგილე`,
    description: `ქალბატონი ნინო თანდილაშვილი 2023 წლის აპრილიდან არის
საქართველოს გარემოს დაცვისა და სოფლის მეურნეობის მინისტრის
პირველი მოადგილე (მინისტრის მოადგილე - 12.2017- 04.2023 წლებში). ის
პასუხისმგებელია ეროვნული გარემოსდაცვითი პოლიტიკის ძირითად
მიმართულებებზე, მათ შორის, კლიმატის ცვლილება, ატმოსფერული
ჰაერის დაცვა, წყლის რესურსების მართვა, ასევე საკანონმდებლო ჩარჩოს
შემუშავება, გარემოსდაცვითი განათლებისა და გარემოსდაცვითი
გადაწყვეტილებების მიღების პროცესში საზოგადოების მონაწილეობის
ხელშეწყობა და გარემოსდაცვით ინფორმაციასთან ხელმისაწვდომობის
უზრუნველყოფა. გარდა ამისა, მისი პასუხისმგებლობის ქვეშ არის ისეთი
საკითხები, როგორიც არის გარემოს დაბინძურების მონიტორინგი,
მეტეოროლოგიური, ჰიდროლოგიური და გეოლოგიური პროცესების
მონიტორინგი, ასევე გარემოზე დაკვირვების მონაცემთა შეკრება და
ეროვნულ და საერთაშორისო დონეებზე გავრცელება.
ამჟამინდელ პოზიციამდე, ქალბატონ ნინო თანდილაშვილს ეკავა გარემოსა
და ბუნებრივი რესურსების დაცვის სამინისტროში იურიდიული
დეპარტამენტის ხელმძღვანელის მოადგილის პოზიცია და პასუხისმგებელი
იყო გარემოსდაცვითი კანონმდებლობის მომზადებასა და გარემოსდაცვითი
მიმართულებით ევროკავშირთან ინტეგრაციასთან დაკავშირებული
სამართლებრივი ასპექტების კოორდინაციაზე.
2007 წლიდან, ქალბატონი ნინოს კარიერა საინტერესოდ და მრავალმხრივ
განვითარდა და მან შეიძინა მნიშვნელოვანი გამოცდილება
სამართალშემოქმედებისა და გარემოსდაცვით სფეროებში. მან დაასრულა
სამართლის საბაკალავრო პროგრამა ივანე ჯავახიშვილის სახელობის
თბილისის სახელმწიფო უნივერსიტეტში, ასევე მიენიჭა ამსტერდამის
უნივერსიტეტის მაგისტრის აკადემიური ხარისხი ევროპულ და

საერთაშორისო სამართალში.
ქალბატონმა ნინომ პროფესიული საქმიანობა დაიწყო სასჯელაღსრულების,
პრობაციისა და იურიდიული დახმარების საკითხთა სამინისტროში, როგორც
საჯარო მოხელემ, ხოლო 2014-2017 წლებში მას ეკავა პარლამენტთან
ურთიერთობისა და სამართალშემოქმედებითი სამმართველოს
ხელმძღვანელისა და იურიდიული დეპარტამენტის ხელმძღვანელის
მოადგილის პოზიციები საქართველოს გარემოსა და ბუნებრივი რესურსების
დაცვის სამინისტროში.
გარდა, წარმატებული პროფესიული საქმიანობისა, ქალბატონმა ნინო
თანდილაშვილმა მნიშვნელოვანი წვლილი შეიტანა ისეთი სტრატეგიული
გარემოსდაცვითი დოკუმენტების შემუშავებაში, როგორიც არის
„გარემოსდაცვითი შეფასების კოდექსი“, „რადიოაქტიური ნარჩენების
შესახებ“ საქართველოს კანონი, „საქართველოს კანონი წყლის რესურსების
მართვის შესახებ“, „საქართველოს კანონი სამრეწველო ემისიების შესახებ“,
„საქართველოს კანონი გარემოსდაცვითი პასუხისმგებლობის შესახებ“ და
„ატმოსფერული ჰაერის ხარისხის მართვის კანონმდებლობა“. ის ასევე, არის
საქართველოს საზოგადოებრივ საქმეთა ინსტიტუტის ასისტენტ-
პროფესორი და კითხულობს ლექციებს მაგისტრანტებისთვის.`,
    img: ninoTand,
  },
  {
    id: 1,
    name: "დოქტორი მარია პია ანკორა",
    position: `ურბანული განვითარების მთავარი სპეციალისტი, აზიის განვითარების ბანკი
(ADB)`,
    description: `მარია პია ანკორა ბოლო 20 წელია შეიმუშავებს და ახორციელებს მდგრადი
ინვესტირების პროგრამებს და სთავაზობს კონსულტაციებს გარემოსდაცვითი
პოლიტიკის მიმართულებით აზიაში (ჩინეთი, ინდოეთი, მონღოლეთი, პაკისტანი
და სხვა). 2019 წლიდან ზედამხედველობს აზიის განვითარების ბანკის (ADB)
ურბანულ, წყლისა და კლიმატის პროგრამებს სამხრეთ კავკასიის რეგიონში.
არის ტრენტოს უნივერსიტეტის (იტალია) გარემოსდაცვითი ინჟინერიისა და
მიწათსარგებლობის დაგეგმვის მაგისტრის ხარისხის მფლობელი აქვს ცინგხუას
უნივერსიტეტის (პეკინი, ჩინეთი) დოქტორის ხარისხი გარემოს დაცვით
მეცნიერებებსა და ინჟინერიაში.`,
    img: mariaPie,
  },
  {
    id: 2,
    name: "დოქტორი იოანის პაპასი",
    position: `გლობალური მდგრადი ტურიზმის (GSTC) ხმელთაშუაზღვის რეგიონის დირექტორი
GREEN EVOLUTION SA-ს დამფუძნებელი და აღმასრულებელი დირექტორი`,
    description: `დოქტორი იოანის პაპასი არის გლობალური მდგრადი ტურიზმის საბჭოს (GSTC)
ხმელთაშუა ზღვის რეგიონის დირექტორი. ფლობს გამოყენებითი მექანიკის
დოქტორის ხარისხს.
აქვს 30 წელზე მეტი ხნის სამეცნიერო გამოცდილება მდგრადი ტურიზმის,
ენერგეტიკის, ინფრასტრუქტურასა და მშენებლობაში გარემოს ეფექტურობის
საკითხებში. კომპანიებს უწევს ექსპერტულ კონსულტაციას კლიმატთან
ადაპტაციისა და გარემოზე ზემოქმედების შემცირების სტანდარტებთან
დაკავშირებით. არის კურორტების GSTC-ის შე წამყვანი შემფასებელი, მდგრადი
ტურიზმის სერტიფიცირების სტანდარტების აუდიტი, წამყვანი ტრენერი და
სამხრეთ-აღმოსავლეთ ევროპის რეგიონის GSTC-ის სტანდარტების (GSTC)
შემფასებელი. მომხსენებლის სტატუსით მიწვეული იყო არაერთ სემინარსა და
ვებინარზე, მათ შორის ევროკომისიაში, GSTC-სა და Google-ში.`,
    img: papasKaci,
  },
  {
    id: 3,
    name: "ჩილა მეზოსი",
    position: "ევროპის სპა ასოციაციის (ESPA) გენერალური მდივანი",
    description: `ჩილა მეზოსი 2000 წლიდან მუშაობს ევროპის სპა ასოციაციაში (ESPA) და 2018 წლიდან
არის გენერალური მდივანი. მისი საქმიანობა მოიცავს გამაჯანსაღებელი ტურიზმის
სექტორში ჩართული ცენტრალური მთავრობისა და ადგილობრივი თვითმმართველობის
ორგანოების სტრატეგიული პროექტების მართვას.
მას 25 წელზე მეტხნიანი გამოცდილება აქვს საერთაშორისო ტურიზმის ინდუსტრიის
სხვადასხვა სფეროში და ჩართულია ისეთ აქტივობებში როგორიცაა პროფესიული
ქსელები, B2B და B2C გაყიდვები, საზოგადოებასთან ურთიერთობა, ღონისძიებების
ორგანიზება, მენეჯმენტის კონსულტირება, სპა ინდუსტრიის განვითარება,
ტურისტული და ჯანდაცვის კომპანიების კროს მარკეტინგი, სამედიცინო ტურიზმის
პოპულარიზაცია და სპა ობიექტებსა და სადაზღვევო კომპანიებს შორის
თანამშრომლობის ხელშეწყობა.
გენერალური მდივნის როლში ის აქტიურადაა ჩართული ევროკავშირის ტურიზმის
მანიფესტის ჯგუფში EU Tourism Manifesto Group და არის ჯანმრთელობის,
გამაჯანსაღებელი ტურიზმისა და სპა ბიზნესის ინოვაციების არაერთი საერთაშორისო
აქტივობისა თუ თანამშრომლობის ინიციატორი.`,
    img: csillia,
  },
  {
    id: 4,
    name: "დოქტორი კურტ ვონ სტორჩი",
    position: `„EuropeSpa med &amp; wellness GmbH, Wiesbaden“- ის აღმასრულებელი დირექტორი
„Quality in Health Prevention“-ის გენერალური მდივანი
გერმანიის ბაალნეოლოგიისა და კლიმატოლოგიის აკადემიის ვიცე პრეზიდენტი`,
    description: `დოქტორი კურტ ფონ სტორჩი კომპანია EuropeSpa med &amp; wellness GmbH-ის
აღმასრულებელი დირექტორია. იგი, ამავდროულად, არის „ჯანმრთელობის პრევენციის
ხარისხის“ გენერალური მდივანი, STORCH Water Consulting International-ის
აღმასრულებელი დირექტორი, გერმანიის ბალნეოლოგიისა და კლიმატოლოგიის
აკადემიის ვიცე-პრეზიდენტი, გერმანიის სამედიცინო სპა ექსპერტთა ასოციაციის
პრეზიდენტი და გერმანიის სპა ასოციაციის სამეცნიერო წევრი. 2009 წლამდე იყო

ჰიდროგეოლოგების საერთაშორისო ასოციაციის (IAH) მინერალური და თერმული
წყლების კომისიის (CMTW) გენერალური მდივანი.
მისი კომპეტენციები მოიცავს: სამედიცინო და ველნეს-ობიექტებისთვის ხარისხის
სტანდარტებისა და სერტიფიცირების სისტემების შექმნას; სასწავლო კურსების
შედგენასა და ტრენინგების ჩატარებას (თერმული წყალი., ტალახი და კლიმატი, როგორც
თერაპიული აგენტები ბალნეოლოგიაში, ხარისხის ასპექტები გამაჯანსაღებელ
დაწესებულებებსა და სამედიცინო აბაზანებში), საკონსულტაციო მომსახურებას
(ჯანმრთელობის კლინიკებისთვის, ველნეს სასტუმროებისთვის, სპა და ჩამოსასხმელი
კომპანიებისთვის)
მიღებული აქვს ჯილდოები Financial Times Germany (2009) კონცეფციისთვის EuropeSpa
med , ასევე 2 სამეწარმეო ჯილდო (ქალაქ ვისბადენისგან და ჰესენის ფედერაციული
მხარისგან).`,
    img: drCurt,
  },
  {
    id: 5,
    name: "დოქტორი მარკუს კოპლინი",
    position: `ჩრდილოეთ ამერიკის ბალნეოლოგიის ასოციაციის (BANA) პრეზიდენტი`,
    description: `დოქტორი მარკუს კოპლინი არის ჩრდილოეთ ამერიკის ბალნეოლოგიის
ასოციაციის (BANA) პრეზიდენტი. იგი არის ექიმი ნატუროპატი, სპეციალიზაციით
„ინტეგრაციული ფიზიკური და სარეაბილიტაციო მედიცინა“ და „ინტეგრაციული
ონკოლოგია“.
იგი ჰიდროთერმული მედიცინის წამყვანი სპეციალისტია ჩრდილოეთ ამერიკაში,
ამავდროულად, არის კურორტის The Springs Resort-ის (Pagosa Springs,
კოლორადოს შტატი) და Murrieta Hot Springs Resort-ის (კალიფორნიის შტატი)
სამედიცინო დირექტორი. მას აქვს 15 წელზე მეტხნიანი გამოცდილება
კომპლექსური და ქრონიკული დაავადებების მკურნალობაში, ტრადიციულ
მკურნალობასთან ერთად ჰიდროთერმული თერაპიის, კვების, ფიზიკური
მედიცინისა და ბუნებრივი სამკურნალო საშუალებების გამოყენებით.
დოქტორი კოპლინის ნაშრომები და სწავლება მოიცავს გამაჯანსაღებელი
კურორტების როლს 21-ე საუკუნის ჯანდაცვისა და ტურიზმის სფეროში. ის არის

ბალნეოლოგიისა და ჯანმრთელობის საკურორტო მედიცინის საერთაშორისო
ჟურნალის მთავარი რედაქტორი, და ავტორი წიგნისა, სახელწოდებით
„ჯანმრთელობის კურორტის მედიცინა“. მისი ამჟამინდელი საქმიანობა, ასევე,
მოიცავს კლინიკების ხელმძღვანელების კონსულტირებას პაციენტზე
ორიენტირებული მაღალხარისხიანი მომსახურების უზრუნველსაყოფად.`,
    img: coplin,
  },
];
