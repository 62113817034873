import AdminPanel from "../pages/admin-panel";
import Home from "../pages/home";
import Login from "../pages/login";
import Footer from "./footer";
import Navbar from "./navbar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useEffect, useState } from "react";
import CheckIn from "../pages/check-in";
import Home2023 from "../pages/home2023";
import BackgroundVideo from "./backgroundVideo";

function Layout() {
  const token = localStorage.getItem("token");
  const [isAuthenticated, setIsAuthenticated] = useState(
    token === "" || token === null ? false : true
  );
  const location = useLocation();

  return (
    <div
      className="layout"
      style={{
        backgroundImage: "linear-gradient(to right, #121b3b, #696464)",
        color: location.pathname === "/" ? "white" : "black",
      }}
    >
      {location.pathname === "/" && <BackgroundVideo />}
      <Navbar />
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/admin-login"
            element={
              isAuthenticated ? (
                <Navigate replace to="/admin-panel" />
              ) : (
                <Login setIsAuthenticated={setIsAuthenticated} />
              )
            }
          />
          <Route path="/conference-2023" element={<Home2023 />} />
          <Route
            path="/admin-panel"
            element={
              isAuthenticated ? (
                <AdminPanel />
              ) : (
                <Navigate replace to="/admin-login" />
              )
            }
          />
          <Route
            path="/admin-panel/check-in"
            element={
              isAuthenticated ? <CheckIn /> : <Navigate replace to="/" />
            }
          />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
